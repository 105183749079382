.appHeader {
  max-width: 750px;
  height: (128px / 2);
  display: flex;
  padding: 0 (32px / 2);
  background: #fff;
  border-bottom: 6px solid #f6f7f8;
}

.body {
  display: flex;
  align-items: center;
}

.logo {
  width: (64px / 2);
  margin-right: 12px;
}

.name {
  font-size: (36px / 2);
  font-weight: bold;
}
