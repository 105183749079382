/**
 * index.modules
 *
 * @author fukui
 */
@import (reference) '~@falla/less/index';

@spin-font-size-text: 14px;
@spin-font-size-icon: 20px;
@spin-font-weight: 500;
@spin-margin-top-tip: 6px;

@spin-color-text: #22d5a3;
@spin-color-icon: #22d5a3;

@transition-duration-1: 0.1s;
@transition-duration-2: 0.2s;
@transition-duration-3: 0.3s;
@transition-duration-4: 0.4s;
@transition-duration-5: 0.5s;
// 线性
@transition-timing-function-linear: cubic-bezier(0, 0, 1, 1);
// 标准
@transition-timing-function-standard: cubic-bezier(0.34, 0.69, 0.1, 1);
// 过冲
@transition-timing-function-overshoot: cubic-bezier(0.3, 1.3, 0.3, 1);
// 减速
@transition-timing-function-decelerate: cubic-bezier(0.4, 0.8, 0.74, 1);
// 加速
@transition-timing-function-accelerate: cubic-bezier(0.26, 0, 0.6, 0.2);

.baseSpin {
  position: relative;
}

.baseSpinWithTip {
  text-align: center;
}

.baseSpinIcon {
  color: @spin-color-icon;
  font-size: @spin-font-size-icon;
}

.baseSpinTip {
  margin-top: @spin-margin-top-tip;
  font-size: @spin-font-size-text;
  font-weight: @spin-font-weight;
  color: @spin-color-text;
}

.baseSpinLoadingLayer {
  text-align: center;
  user-select: none;
}

.baseSpinChildren {
  position: relative;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    //background-color: rgba(255, 255, 255, 0.6);
    opacity: 0;
    transition: opacity @transition-duration-1 @transition-timing-function-linear;
    pointer-events: none;
    z-index: 999;
  }
}

.baseSpinLoading {
  position: relative;
  user-select: none;
}

.baseSpinLoading .baseSpinLoadingLayerInner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.baseSpinLoading .baseSpinChildren::after {
  opacity: 1;
  pointer-events: auto;
}

.baseSpinIconLoading {
  animation: am-loading-circle 1.1s linear infinite;
}

@keyframes am-loading-circle {
  to {
    transform: rotate(1turn);
  }
}

.maskLoading {
  width: 148px;
  height: 148px;
  background: #8e9095;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg {
  width: 72px;
  height: 72px;

  svg {
    vertical-align: top;
    width: inherit;
    height: inherit;
  }
}
