html {
  font-size: 37.5px;
}
.container {
  width: 680px;
  margin: 0 auto;
  -webkit-user-select: text;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  min-height: 100vh;
}

.header {
  padding: 24px 0 12px 0;
  display: flex;
  justify-content: space-between;
}

.headerRight {
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}

.logo {
  width: 32px;
  height: 32px;
}

.card {
  padding: 12px 0;
  background: #fafafa;
  border-radius: 2px 2px 2px 2px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 24px;
  }
}

.dataItem {
  width: 328px;
  padding: 6px 20px;
  margin-right: 24px;

  &:nth-child(2n) {
    margin-right: 0;
  }
}

.dataItemLabel {
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  line-height: 14px;
  margin-bottom: 4px;
}

.dataItemValue {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #333;
  display: flex;
  align-items: center;

  &.isSpecial {
    color: #8787ff;
  }
}

.iconCoin {
  display: inline-block;
  background: url('./assets/icon-coin.png') no-repeat center/cover;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.iconJoCoin {
  display: inline-block;
  background: url('./assets/icon-jo-coin.svg') no-repeat center/cover;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.footer {
  padding-top: 14px;
  text-align: center;
}

.tips {
  // text-align: center;
  margin-bottom: 32px;
}

.footerLogo {
  margin-bottom: 16px;
  // text-align: center;

  img {
    width: 24px;
    height: 24px;
  }
}

.copyright {
  padding: 24px 0;
}

a {
  font-weight: 500;
  font-size: 12px;
  color: #8787ff;
  line-height: 14px;
  text-align: center;
  text-decoration-line: underline;
}
