.body {
  padding: 20px;
}
.privacyPolicy {
  width: 100%;
  overflow: hidden;

  &.isFromPay {
    max-width: 750px;
    margin: 0 auto;
  }

  .wrapper {
    // background-color: #fcfcfc;
    margin: 0 auto;
    min-height: 100vh;
    line-height: 52px;
    color: #333333;

    .title {
      font-size: 32px;
      font-weight: bold;
      text-align: center;
    }
    .content {
      font-size: 28px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 10px 0;
    font-size: 16px;

    td {
      border: 1px solid #2a3c57;
      text-align: center;
      padding: 5px 8px;
      overflow: hidden;
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-all;
      width: 50%;
    }
  }

  a {
    color: #1677ff;
    transition: all 0.3s ease-in-out;
    margin: 0 5px;

    &:hover,
    &:active {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
}

html[mobile='true'] {
  .privacyPolicy {
    .wrapper {
      .title {
        font-size: 16px;
        margin-bottom: 15px;
      }
      .content {
        font-size: 14px;
      }
    }
    table {
      font-size: 14px;
    }
  }
}
