/**
 * index.modules
 *
 * @author fukui
 */
@import (reference) '~@falla/less/index';

.button {
  position: relative;
  display: inline-block;
  transition: @all-transition-active;
  height: 60px;
  padding: 0 30px;
  border-radius: 30px;
  border: none;
  outline: none;
  font-size: 32px;
  font-weight: 500;

  &.isJoyMiApp {
    &.buttonPrimary {
      background: @joymi-primary-color;

      &.isPlain {
        border: 1px solid @joymi-primary-color;
        color: @joymi-primary-color;

        &:active:not(:disabled) {
          background: @joymi-primary-color;
        }
      }

      &.isLink {
        color: @joymi-primary-color;
        background: none;
      }
    }

    &.buttonPrimaryGradient {
      background: @joymi-primary-gradient-color;
      &.isPlain {
        border: 1px solid @joymi-primary-color;
        color: @joymi-primary-color;

        &:active:not(:disabled) {
          background: @joymi-primary-color;
        }
      }

      &.isLink {
        text-decoration: underline;
        color: @joymi-primary-color;
        background: none;
      }
    }
  }

  &.isArhboApp {
    &.buttonPrimary {
      background: @arhbo-primary-color;

      &.isPlain {
        border: 1px solid @arhbo-primary-color;
        color: @arhbo-primary-color;

        &:active:not(:disabled) {
          background: @arhbo-primary-color;
        }
      }

      &.isLink {
        color: @arhbo-primary-color;
        background: none;
      }
    }

    &.buttonPrimaryGradient {
      background: @arhbo-primary-gradient-color;
      &.isPlain {
        border: 1px solid @arhbo-primary-color;
        color: @arhbo-primary-color;

        &:active:not(:disabled) {
          background: @arhbo-primary-color;
        }
      }

      &.isLink {
        text-decoration: underline;
        color: @arhbo-primary-color;
        background: none;
      }
    }
  }

  &.isYiGoApp {
    &.buttonPrimary {
      background: #ff00a1;

      &.isPlain {
        border: 1px solid #ff38b6;
        color: #ff38b6;

        &:active:not(:disabled) {
          background: #ff38b6;
        }
      }

      &.isLink {
        color: #ff00a1;
        background: none;
      }
    }

    &.buttonPrimaryGradient {
      background: linear-gradient(139deg, #ffaa30 0%, #ff00a1 100%);
      &.isPlain {
        border: 1px solid #ff38b6;
        color: #ff38b6;

        &:active:not(:disabled) {
          background: #ff38b6;
        }
      }

      &.isLink {
        text-decoration: underline;
        color: #ff00a1;
        background: none;
      }
    }
  }

  &.buttonPrimary {
    background: #22d5a3;
    color: #ffffff;

    &.isPlain {
      border: 1px solid #22d5a3;
      background: #ffffff;
      color: #22d5a3;

      &:active:not(:disabled) {
        background: #22d5a3;
        opacity: 1;
        color: #fff;
      }
    }

    &.isLink {
      text-decoration: underline;
      color: #22d5a3;
      background: none;
    }
  }

  &.buttonPrimaryGradient {
    background: linear-gradient(125deg, #59e593 0%, #0acea9 100%);
    color: #ffffff;

    &.isPlain {
      border: 1px solid #22d5a3;
      background: #ffffff;
      color: #22d5a3;

      &:active:not(:disabled) {
        background: #22d5a3;
        opacity: 1;
        color: #fff;
      }
    }

    &.isLink {
      text-decoration: underline;
      color: #22d5a3;
      background: none;
    }
  }

  &.buttonDefault {
    background: #f0f0f0;
    color: #666666;

    &.isPlain {
      border: 1px solid #d9d9d9;
      background: transparent;
      color: #c9c9c9;

      &:active:not(:disabled) {
        color: #999;
        background: #e8e8e8;
        opacity: 1;
      }
    }
  }

  &:active:not(:disabled) {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.buttonLoadingWrap {
  display: flex;
  height: 1.4em;
  align-items: center;
  justify-content: center;
}
